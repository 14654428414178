.Avatar {
    background: #929292;
    border-radius: 8px;
    padding: 50px;
    margin-bottom: 5px;
    width: 100%;
    position: relative;
    &__inner {
        display: flex;
        justify-content: center;
    }
    &__img{
        width: 70%;
    }
    &__cube {
        width: 25px;
        height: 25px;
        background-image: url('../../assets/svg/cube.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}
