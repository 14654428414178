* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
}

::-webkit-scrollbar {
    border-radius: 10px;
}
::-webkit-scrollbar-track {
}
::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 6px;
}
::-webkit-scrollbar-corner {
}
::-webkit-scrollbar-button {
}
img {
    width: 100%;
}