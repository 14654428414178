.ChangeColor {
    background-color: #eaeaea;
    // width: 434px;
    width: 100%;
    &__inner {
        display: flex;
        padding: 10px 10px;
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    &__item,
    &--pipette {
        width: 56px;
        height: 56px;
        cursor: pointer;
        border-radius: 4px;
        border: 6px solid transparent;

        &--pipette {
            position: relative;
            background: conic-gradient(
                from 180deg at 50% 50%,
                #e13a16 0deg,
                rgba(138, 248, 70, 0.92) 195deg,
                rgba(126, 5, 247, 0.91) 356.25deg,
                #e13a16 360deg
            );
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: -5px;
                left: -5px;
                width: 56px;
                height: 56px;
                background-image: url("../../assets/svg/pipette.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20px;
            }
        }
        
        &.active {
            border: 6px solid #21c29c;
            border-radius: 4px;
            pointer-events: none;
            user-select: none;  
        }
    }
}
