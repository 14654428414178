.SelectGender {
    display: flex;
    justify-content: center;
    align-items: center;
    &__inner {
        display: flex;
        align-items: center;
    }
    &__item {
        background: #303030;
        border: 6px solid transparent;
        border-radius: 8px;
        max-width: 580px;
        margin: 0 16px;
        padding: 90px 150px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        &:hover {
            background: #929292;
            border: 6px solid #21c29c;
            border-radius: 8px;
        }
    }
}
