.SelectBox {
    margin-bottom: 10px;
    &__inner {
        height: 300px;
        overflow-y: auto;
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 9px;
    }
    &__item {
        padding: 2px;
        overflow: hidden;
        width: 100px;
        height: 100px;
        background-color: white;
        cursor: pointer;
        border: 6px solid transparent;
        transition: all .3s ease-in-out;
        &.active {
            border: 6px solid #21C29C;
            pointer-events: none;
            user-select: none;
        }
    }
}
