.EditorAvatar {
    display: flex;
    justify-content: center;
    &__inner {
        display: flex;
        justify-content: center;
        max-width: 900px;
    }
    &__left {
        width: 50%;
        margin-right: 18px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    &__right {
        width: 50%;
    }
    &__select-box-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #eaeaea;
        border-radius: 4px;
        padding: 30px 40px;
    }
    &__button {
        width: 100%;
        background: #21c29c;
        border: none;
        border-radius: 4px; 
        color: white;
        padding: 10px 0;
        cursor: pointer;
    }
}
