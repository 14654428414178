.AvatarOptions {
    width: 100%;
    margin-bottom: 10px;
    &__items {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
    }
    &__item {
        padding: 20px 40px;
        background: #eaeaea;
        border-radius: 4px;
        flex: 1 0;
        text-align: center;
        cursor: pointer;
        &.active {
            background: #575656;
            border-radius: 4px;
            color: #e2e2e2;
        }
    }
    
}
